import End from '../assets/directionsIcons/start.svg';
import ForkLeft from '../assets/directionsIcons/fork_left.svg';
import ForkRight from '../assets/directionsIcons/fork_right.svg';
import Merge from '../assets/directionsIcons/merge.svg';
import RampLeft from '../assets/directionsIcons/ramp_left.svg';
import RampRight from '../assets/directionsIcons/ramp_right.svg';
import RoundAboutRight from '../assets/directionsIcons/roundabout_right.svg';
import RoundAboutLeft from '../assets/directionsIcons/roundabout.svg';
import Start from '../assets/directionsIcons/end.svg';
import Straight from '../assets/directionsIcons/straight.svg';
import Train from '../assets/directionsIcons/train.svg';
import FerryTrain from '../assets/directionsIcons/directions_boat.svg';
import TurnLeft from '../assets/directionsIcons/turn_left.svg';
import TurnRight from '../assets/directionsIcons/turn_right.svg';
import TurnSharpLeft from '../assets/directionsIcons/turn_sharp_left.svg';
import TurnSharpRight from '../assets/directionsIcons/turn_sharp_right.svg';
import TurnSlightLeft from '../assets/directionsIcons/turn_slight_left.svg';
import TurnSlightRight from '../assets/directionsIcons/turn_slight_right.svg';
import UTurnLeft from '../assets/directionsIcons/u_turn_left.svg';
import UTurnRight from '../assets/directionsIcons/u_turn_right.svg';
import KeepLeft from '../assets/directionsIcons/keep-left.svg';
import KeepRight from '../assets/directionsIcons/keep-right.svg';

export const maneuverIcons = {
  start: Start,
  end: End,
  'turn-slight-left': TurnSlightLeft,
  'turn-slight-right': TurnSlightRight,
  'turn-sharp-left': TurnSharpLeft,
  'turn-sharp-right': TurnSharpRight,
  'uturn-left': UTurnLeft,
  'uturn-right': UTurnRight,
  'turn-left': TurnLeft,
  'turn-right': TurnRight,
  straight: Straight,
  ramp: TurnSharpLeft,
  'ramp-left': RampLeft,
  'ramp-right': RampRight,
  merge: Merge,
  'fork-left': ForkLeft,
  'fork-right': ForkRight,
  ferry: Train,
  'ferry-train': FerryTrain,
  'roundabout-left': RoundAboutLeft,
  'roundabout-right': RoundAboutRight,
  'keep-left': KeepLeft,
  'keep-right': KeepRight,
};
