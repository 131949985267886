import React, { useEffect, useState } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { maneuverIcons } from '../lib/maneuverIcons';

const DirectionsList = ({ source, destination }) => {
  console.log('DirectionsList ~ source, destination:', source, destination);
  const [steps, setSteps] = useState([]);
  console.log('DirectionsList ~ steps:', steps);
  const [distance, setDistance] = useState('');

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ['places'],
  });

  useEffect(() => {
    if (isLoaded && source?.name && destination?.name) {
      const origin = source?.name; // 'START_ADDRESS_OR_LATLNG'; // e.g., "New York, NY"
      const destinationLocation = destination?.name; // 'END_ADDRESS_OR_LATLNG'; // e.g., "Boston, MA"
      calculateRoute(origin, destinationLocation);
    }
  }, [destination?.name, isLoaded, source?.name]);

  //   const calculateRoute = (origin, destination) => {
  //     try {
  //       const directionsService = new window.google.maps.DirectionsService();

  //       directionsService.route(
  //         {
  //           origin,
  //           destination,
  //           travelMode: window.google.maps.TravelMode.DRIVING,
  //         },
  //         (result, status) => {
  //           if (status === 'OK') {
  //             const distance = result.routes[0].legs[0].distance.text;
  //             setDistance(distance);

  //             // Extract steps with their instructions and maneuver
  //             console.log(
  //               'result.routes[0].legs[0].steps',
  //               result.routes[0].legs[0].steps
  //             );
  //             const steps = result.routes[0].legs[0].steps.map((step) => ({
  //               instructions: step.instructions,
  //               maneuver: step.maneuver || 'default',
  //             }));
  //             setSteps(steps);
  //           } else {
  //             console.error('Directions request failed due to ', status);
  //           }
  //         }
  //       );
  //     } catch (err) {
  //       console.error('calculateRoute ~ err:', err);
  //     }
  //   };

  // Map maneuver to icons
  //   const getIconForManeuver = (maneuver) => {
  //     const icons = {
  //       'turn-left': '⬅️',
  //       'turn-right': '➡️',
  //       merge: '🔀',
  //       straight: '⬆️',
  //       'uturn-left': '↩️',
  //       'uturn-right': '↪️',
  //       default: '⚫',
  //     };
  //     return icons[maneuver] || icons['default'];
  //   };

  //   const maneuverIcons = {
  //     'turn-slight-left': 'fa-solid fa-arrow-up-left',
  //     'turn-slight-right': 'fa-solid fa-arrow-up-right',
  //     'turn-sharp-left': 'fa-solid fa-angles-left',
  //     'turn-sharp-right': 'fa-solid fa-angles-right',
  //     'uturn-left': 'fa-solid fa-arrow-rotate-left',
  //     'uturn-right': 'fa-solid fa-arrow-rotate-right',
  //     'turn-left': 'fa-solid fa-arrow-left',
  //     'turn-right': 'fa-solid fa-arrow-right',
  //     straight: 'fa-solid fa-arrow-up',
  //     'ramp-left': 'fa-solid fa-down-left-and-up-right-to-center',
  //     'ramp-right': 'fa-solid fa-down-right-and-up-left-to-center',
  //     merge: 'fa-solid fa-code-merge',
  //     'fork-left': 'fa-solid fa-code-branch',
  //     'fork-right': 'fa-solid fa-code-branch',
  //     ferry: 'fa-solid fa-ship',
  //     'ferry-train': 'fa-solid fa-train',
  //     'roundabout-left': 'fa-solid fa-circle-arrow-left',
  //     'roundabout-right': 'fa-solid fa-circle-arrow-right',
  //     'keep-left': 'fa-solid fa-chevron-left',
  //     'keep-right': 'fa-solid fa-chevron-right',
  //   };

  const calculateRoute = async (origin, destination) => {
    try {
      const directionsService = new window.google.maps.DirectionsService();
      const result = await directionsService.route({
        origin,
        destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      });
      if (result.status === 'OK') {
        const steps = result.routes[0].legs[0].steps.map((step) => ({
          instructions: step.instructions,
          maneuver: step.maneuver || 'default',
          distance: step.distance
            ? (step.distance.value / 1000).toFixed(2) + ' KM'
            : 'N/A',
        }));
        setSteps(steps);
        setDistance(result.routes[0].legs[0].distance.text);
      }
    } catch (error) {
      console.error('Error fetching directions:', error);
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.route}>
        Route : {`"${source?.name}" To "${destination?.name}"`}
      </h2>
      {/* <h3 style={styles.subtitle}>Distance: {distance}</h3> */}
      <ul style={styles.list}>
        <li style={styles.listItem}>
          <div
            style={{
              display: 'flex',
            }}
          >
            <img
              src={maneuverIcons.start}
              alt={maneuverIcons.start}
              style={{ width: '20px', marginRight: '10px' }}
            />

            {source?.name}
          </div>
        </li>
        {steps.map((step, index) => (
          <li key={index} style={styles.listItem}>
            <div>
              {step.maneuver !== 'default' && (
                <img
                  src={maneuverIcons[step.maneuver]}
                  alt={step.maneuver}
                  style={{ width: '20px', marginRight: '10px' }}
                />
              )}

              <span
                dangerouslySetInnerHTML={{ __html: step.instructions }}
                style={styles.instruction}
              />
            </div>
            <span style={styles.distance}>{step.distance}</span>
          </li>
        ))}
        <li style={styles.listItem}>
          <div
            style={{
              display: 'flex',
            }}
          >
            <img
              src={maneuverIcons.end}
              alt={maneuverIcons.end}
              style={{ width: '20px', marginRight: '10px' }}
            />
            {destination?.name}
          </div>
        </li>
      </ul>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: '#fff',
  },

  route: {
    fontSize: '12px',
    fontWeight: 'light',
    color: '#333',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#333',
  },
  subtitle: {
    fontSize: '18px',
    color: '#555',
    marginBottom: '20px',
  },
  list: {
    listStyleType: 'none',
    padding: 0,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    padding: '10px',
    backgroundColor: '#f9f9f9',
    borderRadius: '6px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    justifyContent: 'space-between',
  },
  icon: {
    fontSize: '20px',
    marginRight: '10px',
  },
  instruction: {
    fontSize: '16px',
    color: '#444',
  },
  distance: {
    fontSize: '14px',
    color: '#666',
    marginLeft: '10px',
  },
};

export default DirectionsList;
